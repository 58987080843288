var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.alertProductList,"items-per-page":-1,"hide-default-footer":"","fixed-header":"","height":_vm.list_height + 'px'},on:{"dblclick:row":function ($event, ref) {
	var item = ref.item;

	return _vm.rowClick(item);
}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [(item.code)?_c('div',[_vm._v(" "+_vm._s(item.code)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [(item.customer_product.length != 0)?_c('div',_vm._l((item.customer_product),function(i,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(i.customer_name)+" ")])}),0):_c('div',[_vm._v(_vm._s("ー"))])]}},{key:"item.customer_pn",fn:function(ref){
var item = ref.item;
return [(item.customer_product.length != 0)?_c('div',_vm._l((item.customer_product),function(i,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(i.customer_pn)+" ")])}),0):_c('div',[_vm._v(_vm._s("ー"))])]}},{key:"item.total_lot",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.total_lot).toLocaleString())+" ")])]}},{key:"item.stockage_quantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.stockage_quantity).toLocaleString())+" ")])]}},{key:"item.ordering",fn:function(ref){
var item = ref.item;
return [(item.orders_count || item.received_orders_count)?_c('div',[_vm._v(" "+_vm._s("◯")+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }